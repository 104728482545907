import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import * as style from "./style.module.scss"
import {v4 as uuid} from "uuid"
import { ReactComponent as Svg } from "./four.svg"

import BlocksContent from '../../blocksContent.js'

const ProdDesc = () => {
  // data
  const { sanityYourFirmPage: data } = useStaticQuery(graphql`
    query prodQuery {
      sanityYourFirmPage {
        prodGridMain {
          paCardGrid {
            prodDesc
            prodDescBlock {
              _rawColumnContent
            }
            titleIcon {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className={style.wrapper}>
      <div className={style.svgMax}>
        <div className={style.wrapperInner}>
          <Svg className={style.animSvg} />
          <div className={style.gridInner}>
            {data.prodGridMain.paCardGrid.map(grid => (
              <div className={style.gridItem} key={uuid()}>
                <div>
                  <GatsbyImage
                    image={grid.titleIcon.asset.gatsbyImageData}
                    alt={grid.titleIcon.alt}
                    className={style.prodImg}
                    imgStyle={{ objectFit: "contain" }} />
                  {grid.prodDescBlock && 
                   <BlocksContent blocks={grid.prodDescBlock._rawColumnContent} />
                  }


                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProdDesc
