import React from 'react'

const CtaIllustration = ({children, className}) => {
    return (
        <div className={className}>
        {children}
        </div>
    )
}

export default CtaIllustration
