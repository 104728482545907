import React from "react"
import BlocksContent from "./blocksContent"
import { GatsbyImage } from "gatsby-plugin-image"
import SvgWave from "../images/svgWave"
import "./heroHeader.sass"

const HeroHeader = ({ data }) => {
  return (
    <div id="hero_header">
      <div className="container">
        <div className="main-content">
          {data.mainContent && (
            <BlocksContent blocks={data.mainContent._rawColumnContent} />
          )}
        </div>
        <div
          className="sub-content"
          style={{ color: data.waveColor === "#FED141" ? "#285780" : "white" }}
        >
          {data.subContent && (
            <BlocksContent blocks={data.subContent._rawColumnContent} />
          )}
        </div>
        <div className="feature-image">
          {data.featureImage && data.featureImage.asset && (
            <GatsbyImage
              image={data.featureImage.asset.gatsbyImageData}
              alt={data.featureImage.asset.originalFilename}
            />
          )}
        </div>
      </div>
      <SvgWave color={data.waveColor || "#FED141"} />
    </div>
  )
}

export default HeroHeader
