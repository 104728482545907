import React from 'react'

const CtaText = ({
  className,
  ctaTitle,
  subtitleClass,
  ctaSubTitle,
  ctaText,
  children,
}) => {
  return (
    <div className={className}>
      <h2>{ctaTitle}</h2>
      <p className={subtitleClass}>{ctaSubTitle}</p>
      <p>{ctaText}</p>
      <div>{children}</div> {/* Provide Extra Layout Options */}
    </div>
  )
}

export default CtaText
