import React from "react"

const SvgWave = ({ color = "#FED141" }) => {
  return (
    <svg viewBox="0 0 1440 586" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1123.5 180C1253.88 180 1374 127.5 1440 88.4999V554.399C1169.5 621.899 1006.5 566 709 520.5C459.061 482.274 201.5 489.001 0 554.399V53.5001C440.5 -42.9985 654.614 13.1022 764.5 65.0006C954 154.5 954.542 180 1123.5 180Z"
        fill={color} max-height="500px"
      />
    </svg>
  )
}

export default SvgWave
