import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({
  title = "",
  description = "",
  keywords = "",
  url = "",
  post = "",
  image = "",
}) => {
  if (image === null) {
    image =
      "https://cdn.sanity.io/images/9pqo016l/production/f7af5cfc625824dcbd436d67d2905d932a858a5b-2353x1308.jpg?w=800&h=445&fit=crop&fm=webp"
  }

  var titleString = "Actionstep"
  if (title) {
    titleString = title
  }

  if (description === null) {
    description =
      "Actionstep is a powerful cloud-based legal practice & case management software with everything you need to run your entire law firm."
  }

  if (url === null) {
    url = "https://www.actionstep.com/"
  } else {
    url = `https://www.actionstep.com${url}/`
  }
  return (
    <div className="seo">
      <Helmet title={titleString}>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <html lang="en" />

        <meta property="og:title" content={titleString} />
        <meta property="og:type" content={post ? `article` : `website`} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content={description} />
        <meta name="twitter:title" content={titleString} />
        <meta name="twitter:image" content={image} />
        <meta property="twitter:site" content={url} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </div>
  )
}

export default SEO
