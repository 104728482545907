import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Styles
import * as style from "./styles.module.scss"

// Functional CTa Illustration Comp
import CtaIllustration from "../../global/ctaIllustration"
import CtaButton from "../../global/ctaIllustration/CtaButton"
import CtaText from "../../global/ctaIllustration/CtaText"
import SideImage from "../../global/ctaIllustration/SideImage"
import MyLink from '../../myLink'


// Generate unique keys for list items and items in array
import { v4 as uuid } from "uuid"

// Animated SVG 
import {ReactComponent as Svg} from "./two.svg"
import { ReactComponent as Svg3 } from "./three.svg"


const IllustrationCta = () => {
  // Data

  const { sanityYourFirmPage: data } = useStaticQuery(graphql`
    query illustrationQuery {
      sanityYourFirmPage {
        illustratedCta {
          backgroundColorIllustrationBannerChoose
          ctaTextIllustration {
            ctaText
            subTitle
            title
            ctaButtons {
              ... on SanityLink {
                _key
                _type
                internallink
                linktext
                newwindow
                url
                }
             
            }
          }
          ctaIllustration {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)
  return (
    <CtaIllustration className={style.wrapper}>
      <div className={style.svgMax}>
        <Svg className={style.svgAnim} />
        <div
          style={{
            backgroundColor:
              data.illustratedCta.backgroundColorIllustrationBannerChoose,
          }}
          className={style.colorBlock}
        >
          <Svg3 className={style.svgAnimTwo} />
        </div>
        <div className={style.wrapperInner}>
          <div className={style.TextWrapper}>
            <CtaText
              key={uuid()}
              ctaTitle={data.illustratedCta.ctaTextIllustration.title}
              ctaSubTitle={data.illustratedCta.ctaTextIllustration.subTitle}
              ctaText={data.illustratedCta.ctaTextIllustration.ctaText}
              className={style.TextInner}
            ></CtaText>
            <div className={style.buttonWrap}>
            {data.illustratedCta.ctaTextIllustration.ctaButtons.map(button => {

              return (
                       <MyLink {...button} className={style.button}/>
                        )

              // if (button.href)
              //   return (
              //     <a
              //       className={
              //         button.kind === "button" ? style.button : style.link
              //       }
              //       target={button.newWindow ? "_blank" : "_self"}
              //       href={button.href}
              //       key={uuid()}
              //     >
              //       {button.linkText}
              //     </a>
              //   )
              // return (
              //   <CtaButton
              //     className={style.ctaButtonCont}
              //     buttonLink={`/${button.internalLink.slug.current}`}
              //     buttonText={button.linkText}
              //     buttonClass={
              //       button.kind === "button" ? style.button : style.link
              //     }
              //     key={uuid()}
              //   />
              // )
            })}
            </div>
          </div>
          <SideImage
            ContainerClass={style.ImageContainer}
            alt="{data.illustratedCta.ctaIllustration.alt}"
            fluid={data.illustratedCta.ctaIllustration.asset.gatsbyImageData}
          />
        </div>
      </div>
    </CtaIllustration>
  )
}
export default IllustrationCta
