import React, { useContext, useState, useEffect } from "react"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import { LocationsContext } from "../../../contexts/locationsContext"
import { v4 as uuid } from "uuid"
import MyLink from "../../myLink.js"
// import Image from "gatsby-image"

const PracticeGrid = () => {
  //Data

  const {
    sanityYourFirmPage: { practiceAreas: data },
  } = useStaticQuery(graphql`
    query practiceAreasQuery {
      sanityYourFirmPage {
        practiceAreas {
          paTitle
          paGrid {
            loactions {
              locations {
                countryCode
              }
            }
            backgroundColorCtaText
            paTitle
            podText
            paButton {
              url
              newwindow
              linktext
              internallink
              _key
              _type
            }
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(data.paGrid)

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(data.paGrid)
    } else {
      const filteredData = data.paGrid.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  return (
    <div className={style.wrapper}>
      <div className={style.wrapperInner}>
        <div className={style.gridTitle}>
          <h2>{data.paTitle}</h2>
        </div>
        <div className={style.gridCont}>
          {integrations.length > 0 ? (
            integrations.map(gridItem => (
              <div className={style.gridItem} key={uuid()}>
                <h3 className={style.gridItemTitle}>{gridItem.paTitle}</h3>
                <p>{gridItem.podText}</p>
                {gridItem.paButton && gridItem.paButton.url && (
                  <Link
                    className={style.gridButton}
                    to={`/practice-areas${gridItem.paButton.url}/`}
                  >
                    <button>{gridItem.paButton.linktext}</button>
                  </Link>
                )}

                {gridItem.backgroundColorCtaText && (
                  <div
                    className={style.bottomBorder}
                    style={{
                      backgroundColor: gridItem.backgroundColorCtaText,
                      height: "7px",
                    }}
                  ></div>
                )}
              </div>
            ))
          ) : (
            <p className="empty-notice">
              Sorry, there are no practise areas avalible in region:{" "}
              {currentLocation.countryName}
            </p> // if no integration avalible, return this paragraph
          )}
        </div>
      </div>
    </div>
  )
}

export default PracticeGrid
