// extracted by mini-css-extract-plugin
export var ImageContainer = "styles-module--ImageContainer--pJ2fp";
export var TextInner = "styles-module--TextInner--TGb3u";
export var TextWrapper = "styles-module--TextWrapper--X+9qB";
export var button = "styles-module--button--ORwj1";
export var buttonWrap = "styles-module--buttonWrap--aYC30";
export var colorBlock = "styles-module--colorBlock--x9juP";
export var isStyleH1 = "styles-module--is-style-h1--nCcPZ";
export var isStyleH2 = "styles-module--is-style-h2--dR9EZ";
export var isStyleH3 = "styles-module--is-style-h3--OiquM";
export var isStyleH4 = "styles-module--is-style-h4--X4vFT";
export var isStyleH5 = "styles-module--is-style-h5--m1n9r";
export var isStyleH6 = "styles-module--is-style-h6--y2VsK";
export var svgAnim = "styles-module--svgAnim--eMbt7";
export var svgAnimTwo = "styles-module--svgAnimTwo--jfrNW";
export var svgMax = "styles-module--svgMax--10ed4";
export var wrapper = "styles-module--wrapper--w8jAC";
export var wrapperInner = "styles-module--wrapperInner--bP0Iw";