import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

function SideImage({
  ContainerClass,
  fluid="",
  alt,
  className,
  imageClass,
  style,
  imgStyle,
  children,

}) {
  return (
    <div className={ContainerClass}>
      <GatsbyImage
        image={fluid}
        alt={alt}
        className={imageClass}
        style={style}
        imgStyle={imgStyle} />
      <div>{children}</div> {/* Provide Extra Layout + Content Options */}
    </div>
  );
}

export default SideImage
