// extracted by mini-css-extract-plugin
export var bottomBorder = "style-module--bottomBorder--X8nAv";
export var button = "style-module--button--0JbOJ";
export var gridButton = "style-module--gridButton--HoLF2";
export var gridCont = "style-module--gridCont--XPPGU";
export var gridItem = "style-module--gridItem--+BB6f";
export var gridItemTitle = "style-module--gridItemTitle--s9Vb7";
export var gridTitle = "style-module--gridTitle--pi1wg";
export var isStyleH1 = "style-module--is-style-h1--8tWH3";
export var isStyleH2 = "style-module--is-style-h2--s-A-s";
export var isStyleH3 = "style-module--is-style-h3--y3EPD";
export var isStyleH4 = "style-module--is-style-h4--zzJ3U";
export var isStyleH5 = "style-module--is-style-h5--1dY5F";
export var isStyleH6 = "style-module--is-style-h6--i70tw";
export var link = "style-module--link--AnDfY";
export var wrapper = "style-module--wrapper--nxvHi";
export var wrapperInner = "style-module--wrapperInner--+T56T";