import React from "react"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuid} from "uuid"

// Import Cta Buttons
import CtaButton from "../../global/ctaIllustration/CtaButton"
import MyLink from '../../myLink'



const MoreInfo = () => {
  // Data
  const { sanityYourFirmPage: data } = useStaticQuery(graphql`
    query bottomCta {
      sanityYourFirmPage {
        bottomPageCt {
          title
          subTitle
          ctaText
          ctaButtons {
            ... on SanityLink {
                _key
                _type
                internallink
                linktext
                newwindow
                url
                }
          }
        }
      }
    }
  `)
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperInner}>
        <h2>{data.bottomPageCt.title}</h2>
        <p>{data.bottomPageCt.subTitle}</p>
        <p>{data.bottomPageCt.subTitle}</p>
        <div className={style.buttonContainer}>
          {data.bottomPageCt.ctaButtons.map(button => {
              return (
              <MyLink {...button} className={style.button}/>
                  )

          })}
        </div>
      </div>
    </div>
  )
}

export default MoreInfo
