import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/practice-area/seo"
import HeroHeader from "../components/practice-area/heroHeader"
import IllustrationCta from "../components/practice-area/illustrationCta"
import PracticeGrid from "../components/practice-area/PracticeGrid"
import ProdDesc from "../components/practice-area/prodDesc"
import MoreInfo from "../components/practice-area/moreInfoCta"

const practiceArea = () => {
  return (
    <Layout>
      <SEO />
      <HeroHeader />
      <PracticeGrid />
      <IllustrationCta />
      <ProdDesc />
      <MoreInfo />
    </Layout>
  )
}

export default practiceArea
